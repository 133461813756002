<template>

  <el-dialog title="新增" width="600px" :visible.sync="addVisible" :before-close="closeEvent">
    <el-form ref="form" label-width="100px">
      <el-form-item size="small" label="厂家名称:" required>
        <el-input  v-model="manufactorName" style="width: 435px;" placeholder="请输入厂家名称" maxlength="64" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="地址:" required>
        <el-input  v-model="address" style="width: 435px;" placeholder="请输入地址" maxlength="255" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="电话:" required>
        <el-input  v-model="telephone" style="width: 435px;" placeholder="请输入电话" maxlength="20" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="联系人1:" required>
        <el-input  v-model="contactsOne" style="width: 435px;" placeholder="请输入联系人" maxlength="32" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="手机号1:" required>
        <el-input  v-model="phoneOne" style="width: 435px;" placeholder="请输入手机号" maxlength="11" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small"  label="联系人2:" required>
        <el-input v-model="contactsTwo" style="width: 435px;" placeholder="请输入联系人" maxlength="32" show-word-limit></el-input>
      </el-form-item>
      <el-form-item size="small" label="手机号2:" required>
        <el-input v-model="phoneTwo" style="width: 435px;" placeholder="请输入手机号" maxlength="11" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="closeEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="saveBbiSupplier">确 定</el-button>
        </span>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        manufactorName: '',
        address: '',
        telephone: '',
        contactsOne: '',
        phoneOne: '',
        contactsTwo: '',
        phoneTwo: '',
      }
    },
    props: {
      addVisible: Boolean
    },
    methods: {
      closeEvent() {
        this.$emit('closeInsert')
      },
      async saveBbiSupplier() {
        let data = {
          manufactorName: this.manufactorName,
          address: this.address,
          telephone: this.telephone,
          contactsOne: this.contactsOne,
          phoneOne: this.phoneOne,
          contactsTwo: this.contactsTwo,
          phoneTwo: this.phoneTwo,
        };

        if (!this.manufactorName) {
          this.$message.error("请输入厂家名称");
          return
        }
        if (!this.address) {
          this.$message.error("请输入地址");
          return
        }
        if (!this.telephone) {
          this.$message.error("请输入电话");
          return
        }
        if (!this.contactsOne) {
          this.$message.error("请输入联系人1");
          return
        }
        if (!this.phoneOne) {
          this.$message.error("请输入手机号1");
          return
        }
        if (!this.contactsTwo) {
          this.$message.error("请输入联系人2");
          return
        }
        if (!this.phoneTwo) {
          this.$message.error("请输入手机号2");
          return
        }
        let res = await this.$post("/admin/save_bbiSupplier", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$emit('addSuccess')
        }else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>
